function getElementRoot(realId) {
    const embeddedElement = document.querySelector(`[flow-id="${realId}"]`);
    
    return embeddedElement ? embeddedElement.shadowRoot : document;
}

function getCriteriaKeys(formValues, prefix) {
    return Object.keys(formValues).filter(fieldName => fieldName.indexOf(prefix) === 0);
}

let noFill = false;
const valuePrefix = '';

const mapper = [
  { screenName: 'ir', selector: 'IR result', value: 'Success IR' },
  { screenName: 'new-office-l1', selector: 'L1 New Office result', value: 'Success L1 New Office' },
  { screenName: 'o1-or-eb1a', selector: 'O1 or EB1A result', value: 'Success O1 or EB1A' },
  { screenName: 'o1-or-eb1a-or-eb1b-or-eb2-niw', selector: 'O1 or EB1 or EB2 NIW result', value: 'Success O1 or EB1 or EB2 NIW' },
  { screenName: 'o1-eb1a-eb1b', selector: 'O1 or EB1 result', value: 'Success O1 or EB1' },
  { screenName: 'diversity-visa', selector: 'Diversity Visa result', value: 'Success Diversity Visa' },
  { screenName: 'e2', selector: 'E2 result', value: 'Success E2' },
  { screenName: 'l1', selector: 'L1 result', value: 'Success L1' },
  { screenName: 'e1', selector: 'E1 result', value: 'Success E1' },
  { screenName: 'tn-or-perm', selector: 'TN or PERM result', value: 'Success TN or PERM' },
  { screenName: 'e3-or-perm', selector: 'E3 or PERM result', value: 'Success E3 or PERM' },
  { screenName: 'h1b1-or-perm', selector: 'H1B1 or PERM result', value: 'Success H1B1 or PERM' },
  { screenName: 'h1b-or-perm-or-perm-green-card', selector: 'H1B or PERM Special Handling result', value: 'Success H1B or PERM Special Handling' },
  { screenName: 'h1b-or-perm', selector: 'H1B or PERM result', value: 'Success H1B or PERM' },
  { screenName: 'h2b', selector: 'H2B result', value: 'Success H2B' },
  { screenName: 'i-visa-or-eb1a', selector: 'I Visa or EB1A result', value: 'Success I Visa or EB1A' },
  { screenName: 'p1-or-eb1a', selector: 'P1 or EB1A result', value: 'Success P1 or EB1A' },
  { screenName: 'j1-visa', selector: 'J1 result', value: 'Success J1' },
  { screenName: 'e1-or-e2', selector: 'E1 or E2 result', value: 'Success E1 or E2' },
  { screenName: 'r1-or-eb4', selector: 'R1 or EB4 result', value: 'Success R1 or EB4' },
  { screenName: 'l1-or-eb1c', selector: 'L1 or EB1C result', value: 'Success L1 or EB1C' },
  { screenName: 'diversity-visa-lottery1', selector: 'Diversity Visa Lottery result', value: 'Success Diversity Visa Lottery' },
  { screenName: 'eb2-niw', selector: 'EB2 NIW result', value: 'Success EB2 NIW' },
  { screenName: 'eb5', selector: 'EB5 result', value: 'Success EB5' },
  { screenName: 'j1', selector: 'J1 Visa result', value: 'Success J1 Visa' },
  { screenName: 'b1', selector: 'B1 result', value: 'Success B1' },
  { screenName: 'b2', selector: 'B2 result', value: 'Success B2' },
  { screenName: 'f3', selector: 'F3 result', value: 'Success F3' },
  { screenName: 'f1-family-green-card', selector: 'F1 Family Green Card result', value: 'Success F1 Family Green Card' },
  { screenName: 'f1-student-visa', selector: 'F1 Student Visa result', value: 'Success F1 Student Visa' },
  { screenName: 'm', selector: 'M Visa result', value: 'Success M Visa' },
  { screenName: 'f2b', selector: 'F2B result', value: 'Success F2B' },
  { screenName: 'f4', selector: 'F4 result', value: 'Success F4' },
  { screenName: 'k1', selector: 'K1 result', value: 'Success K1' },
  { screenName: 'f2a', selector: 'F2A result', value: 'Success F2A' },
  { screenName: 'i130', selector: 'i130 result', value: 'Success i130' },
  { screenName: 'i130-or-k1', selector: 'i130 or K1 result', value: 'Success i130 or K1' },
  { screenName: 'h1b-lottery', selector: 'H1B Lottery result', value: 'Success H1B Lottery' },
  { screenName: 'perm', selector: 'PERM result', value: 'Success PERM' },
  { screenName: 'eb1b', selector: 'EB1B result', value: 'EB1B success' },
  { screenName: 'sorry-screen', selector: 'Quiz result', value: 'No immediate options' },
];

function fillFinalInput({ event, selector, value }) {
    setTimeout(() => {
        if (noFill) return;
        
        const realId = event.detail.flowID;
        const elementRoot = getElementRoot(realId);
        const targetInput = elementRoot.querySelector('input[data-label="' + selector + '"]');
        
        if (targetInput) {
            targetInput.value = valuePrefix + value;
            console.log('FILLED', selector, valuePrefix + value);
        } else {
            console.log('NO TARGET INPUT');
        }
    }, 100);
}

window.addEventListener('heyflow-screen-view', (event) => {
	const targetConfig = mapper.find(config => config.screenName === event.detail.screenName);
	
    if (targetConfig) {
        fillFinalInput({ 
            event, 
            selector: targetConfig.selector, 
            value: targetConfig.value
        });
    }
});


function autoSubmit(screenName) {
    const devOrigin = "https://app.heyflow.com";
    const screenNames = ['ir', 'new-office-l1', 'eb1b', 'o1-or-eb1a', 
                        'o1-or-eb1a-or-eb1b-or-eb2-niw','o1-eb1a-eb1b',
                        'diversity-visa','e2','l1','e1','tn-or-perm',
                        'e3-or-perm','h1b1-or-perm','h1b-or-perm-or-perm-green-card',
                        'h1b-or-perm','h2b','i-visa-or-eb1a','p1-or-eb1a',
                        'j1-visa','e1-or-e2','r1-or-eb4','l1-or-eb1c',
                        'diversity-visa-lottery1','eb2-niw','eb5','j1',
                        'b1','b2','f3','f1-family-green-card','f1-student-visa',
                        'm','f2b','f4','k1','f2a','i130','i130-or-k1','h1b-lottery',
                        'perm','sorry-screen'
    ];
    if (screenNames.includes(screenName) && window.location.origin !== devOrigin) {
        const buttonText = 'submit-heyflow-quiz-result';
        const xpath = `//section[@name='${screenName}']//button[contains(., '${buttonText}')]`
        const button = document.evaluate(xpath, document, null, XPathResult.ANY_TYPE, null ).iterateNext();
        if(button instanceof HTMLButtonElement) {
            const container = document.querySelector(`section[name="${screenName}"]`);
            setTimeout(() => {
                const newButton = button.cloneNode(true);
                newButton.style.opacity = 0;
                container.appendChild(newButton);
                newButton.click();
                setTimeout(() => {
                    container.removeChild(newButton);
                }, 5000)
            }, 1500)
        } 
    }
    
}
function autoSubmitListener(event) {
    autoSubmit(event.detail.screenName)
}
window.addEventListener('heyflow-screen-view', autoSubmitListener);

function isDev() {
    const devOrigin = "https://app.heyflow.com";
    return window.location.origin === devOrigin;
}

let valuesHistory = {};

window.addEventListener('heyflow-change', (event) => {
    valuesHistory = event.detail.fieldsSimple || {};
});

function customAsyleeRedirect(event) {
    const nextPage = document.querySelector('section[name="asylee-status-details"]');
    const value = valuesHistory['What is your current immigration status?'];
    const flowID = event.detail.flowID;
    const screenName = event.detail.screenName;
    if (value === "Asylee" && screenName === "visa-list-dropdown" && !isDev()) {
        setTimeout(() => {
            window.heyflow[flowID].navigation.navigate('go', nextPage.id, null, { noValidate: true });
        }, 0);
    }
    
}

window.addEventListener('heyflow-screen-leave', customAsyleeRedirect);


